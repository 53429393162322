import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRamp: BrandVariants = {
    10: "#060200",
    20: "#251206",
    30: "#3F1A0C",
    40: "#55200E",
    50: "#6B2610",
    60: "#822C10",
    70: "#9A3110",
    80: "#B33610",
    90: "#CC3C10",
    100: "#E5410E",
    110: "#FC4B15",
    120: "#FF6B3A",
    130: "#FF875C",
    140: "#FF9F7C",
    150: "#FFB69B",
    160: "#FFCBB8"
};

const iconBrandRamp: BrandVariants = {
  10: "#030208",
  20: "#111435",
  30: "#012161",
  40: "#142D6B",
  50: "#233A75",
  60: "#314880",
  70: "#3E558A",
  80: "#4C6394",
  90: "#5A719D",
  100: "#6880A7",
  110: "#788EB1",
  120: "#879DBB",
  130: "#98ABC5",
  140: "#A9BACF",
  150: "#BAC9D9",
  160: "#CDD8E3"
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(iconBrandRamp),
    colorMeBackground: '#fa450d',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(iconBrandRamp),
    colorMeBackground: '#fa450d',
};

export const iconLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(iconBrandRamp),
    colorMeBackground: '#fa450d',
};

export const iconDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(iconBrandRamp),
    colorMeBackground: '#fa450d',
};

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
